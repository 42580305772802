import React from 'react';

export const PreavisosConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    auth : ['master','monitor'],
    routes  : [
        {
            path     : '/preavisos/:document_id?',
            component: React.lazy(() => import('./Preavisos'))
        }
    ]
};