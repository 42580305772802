/**
 * Authorization Roles
 */
const authRoles = {
    master  : ['master'],
    admin   : ['master', 'admin'],
    agent   : ['master', 'admin', 'agent'],
    user    : ['master', 'admin', 'agent', 'user'],
    precad  : ['master', 'admin', 'agent', 'user', 'precad'],
    onlyGuest: []
};

export default authRoles;
