import React from 'react';

export const VacacionesConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    auth : ['master','monitor'],
    routes  : [
        {
            path     : '/vacaciones/:document_id?',
            component: React.lazy(() => import('./Vacaciones'))
        }
    ]
};