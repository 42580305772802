import React from 'react';

export const AusenciasConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    auth : ['master','monitor'],
    routes  : [
        {
            path     : '/ausencias/:document_id?',
            component: React.lazy(() => import('./Ausencias'))
        }
    ]
};