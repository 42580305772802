import React from 'react';
import { Redirect } from 'react-router-dom';
import { FuseUtils } from '@fuse';
import { LoginConfig } from 'app/main/login/LoginConfig';
import { LogoutConfig } from 'app/main/logout/LogoutConfig';
import { DashboardConfig } from 'app/main/dashboard/DashboardConfig';
import { CompanyListConfig } from 'app/main/companylist/CompanyListConfig';
import { EmployeeListConfig } from 'app/main/employeelist/EmployeeListConfig';
import { DocumentListConfig } from 'app/main/documentlist/DocumentListConfig';
import { DocumentHaberesConfig } from 'app/main/document-haberes/DocumentHaberesConfig';
import { CompanyHashConfig } from 'app/main/company-hash/CompanyHashConfig';
import { AmonestacionesConfig } from 'app/main/amonestaciones/AmonestacionesConfig';
import { AbandonoConfig } from 'app/main/abandono/AbandonoConfig';
import { AccidentesLaboralesConfig } from 'app/main/accidenteslaborales/AccidentesLaboralesConfig';
import { ApercibimientoConfig } from 'app/main/apercibimiento/ApercibimientoConfig';
import { AusenciasConfig } from 'app/main/ausencias/AusenciasConfig';
import { EnfermedadesProfesionalesConfig } from 'app/main/enfermedadesprofesionales/EnfermedadesProfesionalesConfig';
import { PermisosConfig } from 'app/main/permisos/PermisosConfig';
import { PreavisosConfig } from 'app/main/preavisos/PreavisosConfig';
import { SuspencionesConfig } from 'app/main/suspenciones/SuspencionesConfig';
import { VacacionesConfig } from 'app/main/vacaciones/VacacionesConfig';


const routeConfigs = [
    LoginConfig,
    LogoutConfig,
    DashboardConfig,
    CompanyListConfig,
    EmployeeListConfig,
    DocumentListConfig,
    DocumentHaberesConfig,
    AmonestacionesConfig,
    CompanyHashConfig,
    AbandonoConfig,
    AccidentesLaboralesConfig,
    ApercibimientoConfig,
    AusenciasConfig,
    EnfermedadesProfesionalesConfig,
    PermisosConfig,
    PreavisosConfig,
    SuspencionesConfig,
    VacacionesConfig

];

const routes = [
    ...FuseUtils.generateRoutesFromConfigs(routeConfigs),
    {
        path: '/',
        component: () => <Redirect to="/panel-de-control" />
    },
    {
        component: () => <Redirect to="/pages/errors/error-404" />
    }
];

export default routes;
