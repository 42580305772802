import React from "react";

export const Store = React.createContext();

const initialState = {
    registered_person_company_id: null,
    registered_person_company_type: null,
    company_sectors: [],
    confirm_dialog: { 'open' : false, 'title' : 'Motivo de rechazo', 'motivo' : '', 'id': '', 'type': '' },
    update_status: ''
};

function reducer(state, action) {
    switch (action.type) {
        case "SET_PERSON_COMPANY_ID":
            return { ...state, registered_person_company_id: action.payload };
        case "SET_PERSON_COMPANY_TYPE":
            return { ...state, registered_person_company_type: action.payload };
        case "SET_COMPANY_SECTORS":
            return { ...state, company_sectors: action.payload };
        case "SET_CONFIRM_DIALOG":
            return { ...state, confirm_dialog: action.payload };
        case "SET_UPDATE_STATUS":
            return { ...state, update_status: action.payload };
        default:
            return state;
    }
}

export function StoreProvider(props) {
    const [state, dispatch] = React.useReducer(reducer, initialState);
    const value = {
        state: state,
        dispatch: dispatch
    };
    return (
        <Store.Provider value={value}>
            {props.children}
        </Store.Provider>
    );
}
