//import {authRoles} from 'app/auth';

const navigationConfig = [
    {
        'id'      : 'menu',
        'title'   : 'Menú',
        'type'    : 'group',
        'icon'    : 'apps',
        'children': [
            {
                'id'   : 'dashboard',
                'title': 'Panel de Control',
                'type' : 'item',
                'icon' : 'whatshot',
                'url'  : '/panel-de-control',
                'auth' : ['monitor', 'master']
            },
            {
                'id'   : 'empresas',
                'title': 'Empresas',
                'type' : 'item',
                'icon' : 'whatshot',
                'url'  : '/empresas',
                'auth' : ['monitor', 'master']
            },
            {
                'id'   : 'llave',
                'title': 'Llave Empresa',
                'type' : 'item',
                'icon' : 'whatshot',
                'url'  : '/llave-empresa',
                'auth' : ['monitor', 'master']
            }
        ]
    }
];

export default navigationConfig;
